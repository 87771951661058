import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { colors } from '../../styles/colors';

import _Wrapper from '../Wrapper';
import { ReactComponent as LogoLetter } from '../../assets/icons/logo-initial-letter.svg';

export const Container = styled.footer`
  background-color: ${colors.black};

  padding: 16px 20px 20px;
  /* height: 100px; */

  ${mediaQueries('medium')`
    height: 80px;
    padding: 16px 20px 40px;
  `}
`;

export const Wrapper = styled(_Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Languages = styled.ul`
  display: flex;
  margin-bottom: 32px;

  li {
    &:not(:last-child) {
      margin-right: 40px;
    }

    button {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid transparent;
      color: ${colors.goldenHourPrimary};
      border-radius: 0;
      opacity: 0.3;
      position: relative;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
      /* font-family: Raleway; */

      &:hover,
      &.active {
        border-color: ${colors.goldenHourPrimary};
        opacity: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-15px, -50%);
          width: 6px;
          height: 6px;
          background-color: ${colors.goldenHourPrimary};
          border-radius: 3px;
        }
      }
    }
  }
`;

export const Text = styled.small`
  display: block;
  color: ${colors.goldenHourPrimary};
  font-size: 14px;
  margin: 0 11px;
`;

export const LogoIcon = styled(LogoLetter)`
  path:first-of-type {
    fill: ${colors.logo};
  }
`;
