/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

// eslint-disable-next-line react/button-has-type
const Button = ({ children, outline, ...rest }) => (
  <S.Container {...rest} outline={outline}>
    {children}
  </S.Container>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  outline: PropTypes.bool,
};

Button.defaultProps = {
  outline: false,
};

export default Button;
