import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { colors } from '../../styles/colors';
// import { mediaQueries } from '../../styles/media';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 230px;

  ${mediaQueries('bit')`
    font-size: 24px;
    padding-right: 15px;
    margin-bottom: 0;
    width: auto;
  `}
`;

export const Title = styled.h2`
  border-right: 0 solid ${colors.golden};
  font-family: 'Montserrat Light';
  font-size: 14px;
  line-height: 20px;
  color: ${colors.golden};

  padding-right: 10px;
  margin-right: 25px;
  border-width: 1px;

  ${mediaQueries('bit')`
    font-size: 18px;
    line-height: 24px;
  `}

  ${mediaQueries('small')`
    // font-size: 24px;
    // line-height: 30px;
    padding-right: 15px;
    margin-right: 15px;
  `}
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Montserrat Light';
  color: #f1f1f1;

  transition: color 0.2s;

  &:hover {
    color: ${colors.golden};
  }

  ${mediaQueries('bit')`
    font-size: 18px;
    line-height: 24px;
  `}

  /* ${mediaQueries('small')`
    font-size: 24px;
    line-height: 30px;
  `} */
`;
