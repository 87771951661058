export const breakpoints = {
  newMobile: 374,
  bit: 600,
  small: 768,
  medium: 1024,
  large: 1366,
};

export const mediaQueries = key => {
  return style => `@media (min-width: ${breakpoints[key] + 1}px) { ${style} }`;
};
