const translatePT = {
  translation: {
    welcome: {
      title: 'Os Top Chefs agora em Delivery',
      subtitle: 'O menu clássico dos Top Chefs no conforto da sua casa.',
      callInvitation:
        'Se você quiser experenciar algo incrível, peça já o seu convite!',
      inputs: {
        email: 'Escreva o seu endereço de e-mail',
        errorMessageEmail: 'Digite um e-mail válido',
        required: 'O e-mail é obrigatório',
      },
      buttons: {
        invitation: 'PEDIR CONVITE',
      },
      slide: 'Deslize',
      title2: 'Qualified Gourmet Food',
    },
    cards: {
      card1: {
        title: 'A gente quer \nnovidade',
        text:
          'Gurmit leva o melhor \ndas mesas dos restaurantes \npara o conforto da sua casa.',
      },
      card2: {
        title: 'A gente quer \nqualidade',
        text:
          'Todos os nossos restaurantes \npassam por uma criteriosa seleção\n de aprovação',
      },
      card3: {
        title: 'A gente quer \nfelicidade',
        text:
          'Muito mais do que um delivery, \nGurmit é uma experiência. \nO app das pessoas apaixonadas \npela boa comida.',
      },
    },
    appStores: {
      google: 'Disponível no Google Play',
      apple: 'Disponível na App Store',
    },
    downloadApp: {
      title:
        'Baixe a app e aproveite uma experiência gastronômica em sua casa!',
    },
    invite: {
      title: 'E AÍ, DEU VONTADE DO QUÊ? \nPeça o seu convite e experimente!',
      form: {
        placeholder: 'Seu E-mail | Pedir Convite',
        error: '',
        button: 'Pedir convite',
      },
    },
    exclusiveArea: {
      title: 'Área exclusiva dos Parceiros',
      button: 'Acessar Plataforma',
    },
    modals: {
      error: {
        title: 'Ops! Houve algum problema',
        text: 'Houve algum problema, tente novamente mais tarde.',
      },
      success: {
        title: 'Obrigado',
        text:
          'Seu pedido de convite foi solicitado com sucesso! Agora é só aguardar!',
      },
    },
    language: {
      portuguese: 'Português',
      english: 'Inglês',
      spanish: 'Espanhol',
    },
    termsLink: 'Termos e Condições',
  },
};

export default translatePT;
