import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
// import { ReactComponent as ChefIcon } from '../../assets/icons/chef-icon.svg';
// import { ReactComponent as FoodIcon } from '../../assets/icons/food-icon.svg';
// import { ReactComponent as DeliveryIcon } from '../../assets/icons/delivery-icon.svg';
import ChefImage from '../../assets/images/chef.png';
import FoodImage from '../../assets/images/food.png';
import DeliveryImage from '../../assets/images/delivery.png';

const Cards = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Card>
        <S.Image src={DeliveryImage} alt="delivery" />
        <S.BoxInfo>
          <S.Title>{t('cards.card3.title')}</S.Title>
          <S.Text>{t('cards.card3.text')}</S.Text>
        </S.BoxInfo>
      </S.Card>

      <S.Card>
        <S.Image src={FoodImage} alt="food" />
        <S.BoxInfo>
          <S.Title>{t('cards.card2.title')}</S.Title>
          <S.Text>{t('cards.card2.text')}</S.Text>
        </S.BoxInfo>
      </S.Card>
      <S.Card>
        <S.Image src={ChefImage} alt="chef" />
        <S.BoxInfo>
          <S.Title>{t('cards.card1.title')}</S.Title>
          <S.Text>{t('cards.card1.text')}</S.Text>
        </S.BoxInfo>
      </S.Card>
    </S.Container>
  );
};

export default Cards;
