import { createGlobalStyle } from 'styled-components';

// import GothamThin from '../assets/fonts/Gotham/Gotham-Thin.otf';
// import GothamLight from '../assets/fonts/Gotham/GothamLight.ttf';
// import GothamMedium from '../assets/fonts/Gotham/GothamMedium.ttf';
// import GothamBold from '../assets/fonts/Gotham/GothamBold.ttf';

import MontserratBold from '../assets/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratLight from '../assets/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratThin from '../assets/fonts/Montserrat/Montserrat-Thin.ttf';
import Montserrat from '../assets/fonts/Montserrat/Montserrat-Regular.ttf';

export const FontFaces = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${MontserratBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat Light';
    src: url(${MontserratLight}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat Thin';
    src: url(${MontserratThin}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Montserrat}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default FontFaces;
