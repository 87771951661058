import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';

export const StoresContainer = styled.div`
  display: flex;
  margin: 0 -10px;

  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  ${mediaQueries('medium')`
    margin: 0 -5px;
    width: 300px;
    margin: 0 auto;
  `}
`;

export const StoreLink = styled.a`
  display: block;
  width: 100%;
  padding: 0 10px;

  ${mediaQueries('medium')`
    padding: 0 5px;
  `}
`;

export const StoreBadge = styled.picture`
  img {
    width: 100%;

    ${mediaQueries('medium')`
      width: 120px;
    `}
  }
`;
