export const colors = {
  goldenHourPrimary: '#D4A11E',
  warmGrey: '#F3F1E9',
  white: '#fff',
  mainText: '#141414',
  auxiliarText: '#89878b',
  alert: '#d73200',
  auxiliar: '#06E775',
  dark: '#090A0C',
  black: '#010101',
  logo: '#86edd0',
  greenLight: '#b2efda',
  golden: '#b59852',
};
