import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import ModalContainer from '../components/ModalContainer';

const ModalContext = createContext({
  showModal: () => {},
  hideModal: () => {},
});

export const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    component: null,
  });

  const showModal = component =>
    setState({
      component,
    });

  const hideModal = () =>
    setState({
      component: null,
    });

  return (
    <ModalContext.Provider value={{ ...state, showModal, hideModal }}>
      {children}
      <ModalContainer component={state.component} onClose={hideModal} />
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within with ModalProvider');
  }

  return context;
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
