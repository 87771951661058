import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import * as S from './styles';

import GooglePlay from '../../assets/images/google-play.png';
import GooglePlaySmall from '../../assets/images/google-play-small.png';
import AppStore from '../../assets/images/apple-store.png';
import AppStoreSmall from '../../assets/images/apple-store-small.png';

const AppStores = ({ className }) => {
  const { t } = useTranslation();

  function DownloadApp(store) {
    ReactGA.event({
      category: 'Download Apps',
      action: `Download ${store}`,
      label: 'Link to store',
    });
  }

  return (
    <S.StoresContainer className={className}>
      <S.StoreLink
        onClick={() => DownloadApp('Google Play')}
        href="https://play.google.com/store/apps/details?id=br.com.gurmit"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.StoreBadge>
          <source media="(max-width: 1024px)" srcSet={GooglePlay} />
          <source media="(max-width: 1025px)" srcSet={GooglePlaySmall} />
          <img src={GooglePlaySmall} alt={t('appStores.google')} />
        </S.StoreBadge>
      </S.StoreLink>
      <S.StoreLink
        onClick={() => DownloadApp('Apple Store')}
        href="https://apps.apple.com/us/app/id1513735962"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.StoreBadge />
        <S.StoreBadge>
          <source media="(max-width: 1024px)" srcSet={AppStore} />
          <source media="(max-width: 1025px)" srcSet={AppStoreSmall} />
          <img src={AppStoreSmall} alt={t('appStores.apple')} />
        </S.StoreBadge>
      </S.StoreLink>
    </S.StoresContainer>
  );
};

AppStores.propTypes = {
  className: PropTypes.string,
};

AppStores.defaultProps = {
  className: '',
};

export default AppStores;
