import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga';
import i18n from './i18n';
import Routes from './routes';

import { GlobalStyle } from './styles/global';
import FontStyles from './styles/fonts';

import Header from './components/Header';
import Footer from './components/Footer';

import { ModalProvider } from './hooks/Modal';

// const GA_ID = 'UA-139264261-2';
const GA_ID = 'UA-199864636-1';

const App = () => {
  useEffect(() => {
    ReactGA.initialize(GA_ID, {
      // debug: true,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ModalProvider>
        <Router>
          <Header />
          <Routes />
          <Footer />
        </Router>
        <GlobalStyle />
        <FontStyles />
      </ModalProvider>
    </I18nextProvider>
  );
};

export default App;
