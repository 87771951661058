import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';

export const Container = styled.div`
  height: inherit;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    max-width: 200px;
    align-self: baseline;
    position: absolute;
    top: 20px;
    left: 20px;

    ${mediaQueries('small')`
      max-width: 100%;
      top: 50px;
    `}
  }
`;

export const Title = styled.h3`
  font-family: 'Montserrat Bold';
  font-size: 32px;
  line-height: 38px;
  color: ${colors.goldenHourPrimary};
  margin-bottom: 30px;
  margin-top: 100px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: #25282b;
  text-align: center;

  ${mediaQueries('small')`
    font-size: 20px;
    line-height: 32px;
  `}
`;
