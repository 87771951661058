const translateES = {
  translation: {
    welcome: {
      title: 'Los Top Chefs ahora en Delivery',
      subtitle: 'El menú clásico de los Top Chefs en la comodidad de su casa.',
      callInvitation:
        'Si quieres vivir algo increíble, ¡Pide tu invitación ahora!',
      inputs: {
        email: 'Escriba su dirección de e-mail',
        errorMessageEmail: 'Escriba un e-mail válido',
        required: 'El e-mail es obligatorio',
      },
      buttons: {
        invitation: 'PEDIR INVITACIÓN',
      },
      slide: 'Deslice',
      title2: 'Qualified Gourmet Food',
    },
    cards: {
      card1: {
        title: 'TOP CHEFS',
        text: 'Una selección de Chefs renombrados.',
      },
      card2: {
        title: 'GOURMET',
        text: 'Un menú de sabores y experiencias.',
      },
      card3: {
        title: 'DELIVERY',
        text: 'En la comodidad de su casa con calidad.',
      },
    },
    appStores: {
      google: 'Disponible en Google Play',
      apple: 'Disponible en la App Store',
    },
    downloadApp: {
      title: 'Vaya al Menú de sabor y experiencia',
      subtitle:
        'Si usted ya tiene una invitación, pruebe ahora lo  mejor de la gastronomía, baje la app.',
      image: 'Celular con aplicativo del Gurmit abierto',
    },
    invite: {
      title: '¿Estás interesado?',
      subtitle: '¡Pide tu invitación y entra ahora en la lista de espera!',
      form: {
        placeholder: 'Escriba su dirección de e-mail',
        error: '',
        button: 'Pedir invitación',
      },
    },
    exclusiveArea: {
      title: 'Área exclusiva de Miembros',
      button: 'Acceder a la Plataforma',
    },
    modals: {
      error: {
        title: '¡Ups! Hubo algun problema',
        text: 'Hubo algun problema, inténtalo de nuevo más tarde.',
      },
      success: {
        title: 'Gracias',
        text:
          '¡Su solicitud de invitación ha sido solicitada con éxito! ¡Ahora sólo tiene que esperar!',
      },
    },
    language: {
      portuguese: 'Portugués',
      english: 'Inglés',
      spanish: 'Español',
    },
    termsLink: 'Términos y Condiciones',
  },
};

export default translateES;
