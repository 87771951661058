import styled from 'styled-components';

import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';

import Wrapper from '../Wrapper';
import AppStores from '../AppStores';

export const Container = styled.section`
  ${mediaQueries('medium')`
   padding: 90px 0;
  `}
`;

export const Content = styled(Wrapper)`
  position: relative;
  padding-bottom: 120px;

  ${mediaQueries('medium')`
   padding-bottom: 0;
  `}
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 80px;

  ${mediaQueries('medium')`
      margin-bottom: 50px;
  `}
`;

export const Title = styled.h2`
  color: ${colors.golden};
  font-size: 38px;
  line-height: 44px;
  font-family: 'Montserrat Light';
  white-space: break-spaces;
  text-align: left;

  ${mediaQueries('medium')`
    font-size: 18px;
    line-height: 24px;

    text-align: center;
  `}
`;

export const Apps = styled(AppStores)``;
