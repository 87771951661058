import styled, { css } from 'styled-components';

import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';
import Button from '../Button';

export const Container = styled.form`
  width: 100%;
  max-width: 670px;

  ${mediaQueries('medium')`
    max-width: 480px;
  `}
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 80px; */

  /* border: 1px solid ${colors.greenLight}; */
  /* border-radius: 40px; */

  margin-bottom: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  transition: border-color 0.25s;

  ${mediaQueries('medium')`
    margin-bottom: 5px;
    margin-right: 10px;
  `}

  ${props =>
    props.hasError &&
    css`
      border-color: #f12b2c;
    `}
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  /* height: 100%; */

  box-shadow: none;
  border: 0;
  border-radius: 40px;
  border: 1px solid ${colors.greenLight};

  font-size: 20px;
  /* font-family: 'Montserrat Light'; */
  font-family: 'Montserrat Light';

  padding: 0 30px;
  margin-bottom: 15px;

  &::placeholder {
    color: #b3b3b3;
  }

  ${mediaQueries('bit')`
    font-size: 24px;
    height: 80px;
  `}

  ${mediaQueries('medium')`
    font-size: 18px;
    padding-right: 180px;
    height: 48px;
  `}
`;

export const ButtonSubmit = styled(Button)`
  ${mediaQueries('bit')`
    position: absolute;
    right: 0;
    top: 0;
  `}
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  ${mediaQueries('bit')`
    margin-top: 0;
  `}

  svg {
    margin-right: 10px;
  }
`;

export const ErrorMessage = styled.p`
  /* position: absolute;
  left: 20px;
  top: 100%; */
  font-size: 14px;
  line-height: 20px;
  color: #f12b2c;
  font-family: 'Montserrat Bold';
`;
