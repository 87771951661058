import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Title>{t('downloadApp.title')}</S.Title>
        </S.Header>
        <S.Apps />
      </S.Content>
    </S.Container>
  );
};

export default DownloadApp;
