import React from 'react';
// import ReactGA from 'react-ga';
import Welcome from '../../components/Welcome';
import Cards from '../../components/Cards';
import DownloadApp from '../../components/DownloadApp';

const Home = () => {
  // ReactGA.pageview('Homepage');
  return (
    <>
      <Welcome />
      <Cards />
      <DownloadApp />
    </>
  );
};

export default Home;
