import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';
import InviteForm from '../InviteForm';

export const Container = styled.div`
  position: relative;
  margin-top: 80px;
  background: linear-gradient(154.35deg, #010101 54.88%, #505050 107.58%);

  /* height: 684px; */
  .awssld__wrapper {
    max-height: calc(100vh - 150px);
  }

  ${mediaQueries('bit')`
    margin-top: 100px;
  `}
`;

export const Slider = styled(AwesomeSlider)`
  --loader-bar-color: ${colors.golden};
  --loader-bar-height: 1px;
  --slider-height-percentage: 90%;

  ${mediaQueries('small')`
    --slider-height-percentage: 50%;
  `}
`;

export const Claim = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  margin-right: -20px;

  background-color: ${colors.logo};

  width: 313px;
  border-radius: 0 40px;

  display: flex;
  box-sizing: content-box;

  ${mediaQueries('bit')`
    width: 580px;
  `}
`;

export const PlatePicture = styled.picture`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  img {
    /* transform: translate(-30%, -20%); */
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.3);

    transition: all 0.1s;

    ${mediaQueries('medium')`
      transform: scale(1.8);
    `}

    ${mediaQueries('large')`
      transform: scale(1.3);
    `}
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat Light';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: break-spaces;

  ${mediaQueries('bit')`
    font-size: 38px;
    line-height: 54px;
  `}
`;

export const Title = styled(Text)`
  color: ${colors.golden};
`;

export const Subtitle = styled(Text)`
  color: ${colors.mainText};
`;

export const BoxInfo = styled.div`
  padding: 12px 0 12px 12px;
`;

export const LetterIcon = styled.img`
  height: 150px;

  ${mediaQueries('bit')`
    height: 300px;
  `}
`;

export const InviteFormWelcome = styled(InviteForm)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries('small')`
  grid-area: 4 / 2 / 4 / 6;
  margin-top: 16px;
  `}

  ${mediaQueries('medium')`
  grid-area: 4 / 3 / 4 / 6;
  margin-top: 20px;
  flex-direction: row;
  max-width: 535px;
  `}
`;
