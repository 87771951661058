import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Wrapper = ({ children, className }) => (
  <S.Container className={className}>{children}</S.Container>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Wrapper.defaultProps = {
  className: '',
};

export default Wrapper;
