import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { colors } from '../../styles/colors';

import Wrapper from '../Wrapper';

export const Container = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 60px;

  ${mediaQueries('medium')`
    justify-content: space-around;
    flex-direction: row;

    padding-top: 30px;
  `}
`;

export const Card = styled.div`
  display: flex;

  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 50px;

  border-bottom: 1px solid ${colors.golden};

  img {
    margin-right: 30px;
    flex: 1;
    height: 100%;
    max-height: 70px;

    ${mediaQueries('newMobile')`
      max-height: 140px;
    `}

    ${mediaQueries('small')`
      margin: 0 auto 20px;
      // max-height: 140px;
    `}
  }

  ${mediaQueries('medium')`
     width: 240px;
     margin-bottom: 0;
     flex-direction: column;
     border: none;
  `}
`;

export const BoxInfo = styled.div`
  flex: 2;

  /* ${mediaQueries('medium')`
    flex: 2;
  `} */
`;

export const Title = styled.h4`
  color: ${colors.golden};
  /* font-size: 42px;
  line-height: 50px; */
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 15px;
  text-align: left;
  text-transform: uppercase;
  white-space: break-spaces;

  /* ${mediaQueries('small')`
    font-size: 24px;
    line-height: 30px;
  `} */

  ${mediaQueries('medium')`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: center;
  `}
`;

export const Text = styled.p`
  color: ${colors.auxiliarText};
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 2px;
  font-family: 'Montserrat Light';
  white-space: break-spaces;

  ${mediaQueries('medium')`
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: initial;
  `}
`;

export const Image = styled.img`
  object-fit: contain;
`;
