import React from 'react';

import * as S from './styles';

import ExclusiveArea from '../ExclusiveArea';

const Footer = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <ExclusiveArea />
        <S.ContainerLinks>
          <S.LogoIcon />
        </S.ContainerLinks>
      </S.Wrapper>
    </S.Container>
  );
};

export default Footer;
