import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { colors } from '../../styles/colors';

import Wrapper from '../Wrapper';
import { ReactComponent as Logo } from '../../assets/icons/logo-full.svg';

export const Container = styled.header`
  background-color: ${colors.black};

  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  transition: all 0.5s;

  z-index: 2;

  ${mediaQueries('bit')`
    height: 100px;
  `}
`;

export const Content = styled(Wrapper)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${mediaQueries('bit')`
    align-items: center;
    padding-top: 30px;
  `}
`;

export const LogoIcon = styled(Logo)`
  width: 124px;
  height: 35px;
`;

export const Description = styled.img`
  width: 150px;

  ${mediaQueries('bit')`
    width: 300px;
  `}
`;
