import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';

export const Container = styled.div`
  padding: 80px 20px 90px;
  margin: auto;

  ${mediaQueries('small')`
   padding: 130px 40px 90px;
  `}

  ${mediaQueries('medium')`
   padding: 130px 100px 90px;
   max-width: 1024px;
  `}
`;

export const Title = styled.h2`
  font-size: 28px;
  font-family: 'Montserrat';
  line-height: 38px;
  margin-bottom: 90px;
  color: ${colors.mainText};

  ${mediaQueries('small')`
    font-size: 36px;
    line-height: 42px;
  `}

  ${mediaQueries('medium')`
    font-size: 48px;
    line-height: 54px;
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  color: ${colors.mainText};
  white-space: pre-line;
  justify-content: center;
`;

export const SkeletonText = styled(Skeleton)`
  /* width: 100%; */
`;

export const ContainerSK = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1024px;

  font-size: 16px;
  line-height: 2;

  padding: 80px 20px 90px;

  ${mediaQueries('small')`
   padding: 130px 40px 90px;
  `}

  ${mediaQueries('medium')`
   padding: 130px 100px 90px;
  `}

  ${mediaQueries(1224)`
   box-sizing: initial;
  `}

  & > span {
    width: 100%;
  }
`;
