import styled, { css } from 'styled-components';

import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s;
  z-index: 2;

  ${props =>
    props.hasComponent &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  border: 0;

  svg {
    width: 14px;
    height: 14px;

    ${mediaQueries('small')`
      width: initial;
      height: initial;
    `}
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 334px;
  width: 100%;
  height: 300.46px;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  ${mediaQueries('small')`
    max-width: 488px;
    height: 405px;
  `}
`;
