import React from 'react';

import * as S from './styles';

// eslint-disable-next-line react/prop-types
const TermsSkeleton = ({ title }) => {
  return (
    <S.ContainerSK>
      <S.Title>{title}</S.Title>
      <S.SkeletonText duration={1} count={30} />
    </S.ContainerSK>
  );
};

export default TermsSkeleton;
