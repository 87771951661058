import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

const ExclusiveArea = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Content>
        <S.Title>{t('exclusiveArea.title')}</S.Title>
        <S.Link outline as="a" href="https://pop.gurmit.com.br" target="_blank">
          <span>{t('exclusiveArea.button')}</span>
        </S.Link>
      </S.Content>
    </S.Container>
  );
};

export default ExclusiveArea;
