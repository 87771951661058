import i18next from 'i18next';
import translatePT from './translates/translatePT';
import translateEN from './translates/translateEN';
import translateES from './translates/translateES';

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'pt',
  resources: {
    pt: translatePT,
    en: translateEN,
    es: translateES,
  },
});

export default i18next;
