import React, { useEffect, useState } from 'react';
// import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import TermsSkeleton from './skeleton';
import api from '../../services/api';

import * as S from './styles';

const Terms = () => {
  // ReactGA.pageview('Termos e Condições');
  const { t } = useTranslation();
  const [terms, setTerms] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadTerms() {
      const response = await api.get('client/app/info');

      setTerms(response.data.applicationInfo.terms);
      setLoading(false);
    }
    loadTerms();
  }, []);

  return (
    <>
      {loading ? (
        <TermsSkeleton title={t('termsLink')} />
      ) : (
        <S.Container>
          <S.Title>{t('termsLink')}</S.Title>
          <S.Text>{terms}</S.Text>
        </S.Container>
      )}
    </>
  );
};

export default Terms;
