import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import * as Yup from 'yup';
import * as S from './styles';

import ModalError from '../ModalError';
import ModalSuccess from '../ModalSuccess';

import { useModal } from '../../hooks/Modal';

import { ReactComponent as Error } from '../../assets/icons/error.svg';
import api from '../../services/api';

const InviteForm = ({ className }) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email(t('welcome.inputs.errorMessageEmail'))
          .required(t('welcome.inputs.required')),
      });

      await schema.validate(
        { email: value },
        {
          abortEarly: false,
        },
      );

      const response = await api.post('invite/form', {
        email: value,
      });

      const respStatus = response.data.status;

      if (respStatus !== 1) {
        showModal(ModalError);
      } else {
        showModal(ModalSuccess);

        ReactGA.event({
          category: 'Invite',
          action: `Ask for invitation completed`,
          label: 'Invite form',
        });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(errors => {
          errorMessages[errors.path] = errors.message;
        });

        if (Object.keys(errorMessages).length) {
          setError(errorMessages.email);
          return;
        }

        showModal(ModalError);
      }
    }
  }

  function changeHandle(e) {
    if (error) {
      setError('');
    }
    setValue(e.target.value);
  }

  return (
    <S.Container className={className} onSubmit={handleSubmit}>
      <S.InputContainer hasError={!!error}>
        <S.Input
          name="email"
          type="text"
          placeholder={t('invite.form.placeholder')}
          onChange={changeHandle}
          value={value}
        />
        <S.ButtonSubmit type="submit">{t('invite.form.button')}</S.ButtonSubmit>
        {error && (
          <S.ErrorContainer>
            <Error />
            <S.ErrorMessage>{error}</S.ErrorMessage>
          </S.ErrorContainer>
        )}
      </S.InputContainer>
    </S.Container>
  );
};

InviteForm.propTypes = {
  className: PropTypes.string,
};

InviteForm.defaultProps = {
  className: '',
};

export default InviteForm;
