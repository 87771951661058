import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';
import HeaderDescription from '../../assets/images/header-description.png';

const Header = () => {
  return (
    <S.Container>
      <S.Content>
        <Link to="/">
          {/* <S.Logo src={Logo} alt="Gurmit" /> */}
          <S.LogoIcon />
        </Link>
        <S.Description src={HeaderDescription} alt="Qualified Gourmet Food" />
      </S.Content>
    </S.Container>
  );
};

export default Header;
