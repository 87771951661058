import React from 'react';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import * as S from './styles';

import logoInitialLetter from '../../assets/icons/logo-initial-letter.svg';

const images = [
  { source: './static/images/hero/image-01.jpg' },
  { source: './static/images/hero/image-02.jpg' },
  { source: './static/images/hero/image-03.jpg' },
  { source: './static/images/hero/image-04.jpg' },
  { source: './static/images/hero/image-05.jpg' },
  { source: './static/images/hero/image-06.jpg' },
  { source: './static/images/hero/image-07.jpg' },
  { source: './static/images/hero/image-08.jpg' },
  { source: './static/images/hero/image-09.jpg' },
];

const AutoplaySlider = withAutoplay(S.Slider);

const Welcome = () => {
  const title = 'A gente \nquer mais do \nque comida.';
  const subtitle = 'A gente \nquer gurmit.';

  return (
    <S.Container>
      {/* <S.PlatePicture>
        <source media="(max-width: 1024px)" srcSet={plateApp} />
        <source media="(max-width: 1025px)" srcSet={plateDesktop} />
        <img src={plateDesktop} alt="Prato de comida gourmet" />
      </S.PlatePicture> */}
      <AutoplaySlider
        bullets={false}
        organicArrows={false}
        interval={5000}
        play
        cancelOnInteraction={false}
        mobileTouch={false}
        media={images}
      />
      <S.Claim>
        <S.BoxInfo>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.BoxInfo>
        <S.LetterIcon src={logoInitialLetter} alt="Logo" />
      </S.Claim>
    </S.Container>
  );
};

export default Welcome;
