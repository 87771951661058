import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'HANZO-Application-Id': '68',
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers,
});

export default api;
