import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { mediaQueries } from '../../styles/media';

export const Container = styled.button`
  background-color: ${colors.greenLight};
  color: ${colors.mainText};

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 40px;

  border: 0;

  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  font-size: 18px;

  height: 60px;
  width: 100%;
  padding: 0 40px;

  transition: all 0.25s;

  &:hover {
    box-shadow: inset -1px 2px 1px rgba(65, 46, 12, 0.35);
  }

  ${mediaQueries('bit')`
    height: 80px;
    width: auto;

    font-size: 24px;
    line-height: 30px;
  `}

  ${mediaQueries('medium')`
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    min-width: 150px;
    padding: 0 20px;
  `}
`;
