import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

import { ReactComponent as Close } from '../../assets/icons/close-icon.svg';

// eslint-disable-next-line react/prop-types
const ModalContainer = ({ component: Component, onClose }) => {
  useLayoutEffect(() => {
    if (Component) {
      Object.assign(document.body.style, {
        overflow: 'hidden',
      });
    }

    return () => document.body.removeAttribute('style');
  }, [Component]);

  return (
    <S.Container hasComponent={!!Component}>
      {Component && (
        <S.Content>
          <S.CloseButton type="button" onClick={() => onClose()}>
            <Close />
          </S.CloseButton>
          <Component />
        </S.Content>
      )}
    </S.Container>
  );
};

ModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalContainer;
