const translateEN = {
  translation: {
    welcome: {
      title: 'The Top Chefs now available in Delivery',
      subtitle: "The Chef's classic menu in the comfort of your home.",
      callInvitation:
        'If you want to experience something incredible, ask for your invitation now!',
      inputs: {
        email: 'Type your e-mail address',
        errorMessageEmail: 'Type a valid e-mail address',
        required: 'E-mail is required',
      },
      buttons: {
        invitation: 'ASK INVITATION',
      },
      slide: 'Swipe',
      title2: 'Qualified Gourmet Food',
    },
    cards: {
      card1: {
        title: 'TOP CHEFS',
        text: 'A selection of renowned Chefs.',
      },
      card2: {
        title: 'GOURMET',
        text: 'A menu of taste and experiences.',
      },
      card3: {
        title: 'DELIVERY',
        text: 'Quality in the comfort of your home.',
      },
    },
    appStores: {
      google: 'Available on Google Play',
      apple: 'Available on App Store',
    },
    downloadApp: {
      title: 'Access the Taste and Experience Menu',
      subtitle:
        'If you already have an invite, try out the best of cuisine, download the app.',
      image: 'Celular com aplicativo do Gurmit aberto',
    },
    invite: {
      title: 'Interested?',
      subtitle: 'Ask for your invitation and enter the waiting list now!',
      form: {
        placeholder: 'Type your e-mail address',
        error: '',
        button: 'Ask invitation',
      },
    },
    exclusiveArea: {
      title: "Partner's excluvise area",
      button: 'Access Platform',
    },
    modals: {
      error: {
        title: 'Oops! There was a problem',
        text: 'There was a problem, please try again later.',
      },
      success: {
        title: 'Thankyou',
        text:
          'Your invitation request has been successfully requested! Now just wait!',
      },
    },
    language: {
      portuguese: 'Portuguese',
      english: 'English',
      spanish: 'Spanish',
    },
    termsLink: 'Terms and Conditions',
  },
};

export default translateEN;
