import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { ReactComponent as Success } from '../../assets/icons/success-icon.svg';

const ModalSuccess = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Content>
        <Success />
        <S.Title>{t('modals.success.title')}</S.Title>
        <S.Text>{t('modals.success.text')}</S.Text>
      </S.Content>
    </S.Container>
  );
};

export default ModalSuccess;
